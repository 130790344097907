<template>
  <div
    v-if="managedByCustomerName"
    class="flex w-[208px] border-t border-t-[rgba(255,255,255,0.2)] bg-[rgba(255,255,255,0.13)] px-6 py-[9px]"
    :title="$t('officehub.managed_by_tooltip')"
  >
    <div class="flex w-full cursor-default gap-2 overflow-hidden">
      <div class="typo-caption flex grow flex-col overflow-hidden">
        <h4 class="text-grey-300">
          {{ $t("officehub.managed_by") }}
        </h4>
        <p class="truncate font-medium text-white">
          {{ managedByCustomerName }}
        </p>
      </div>
      <div class="typo-caption flex content-center items-center text-grey-400">
        <BaseIcon icon="info-circle" :size="18" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useOfficehub } from "~/utils/use-officehub";

const { managedByCustomerName } = useOfficehub();

interface Props {
  collapsed: boolean;
}

withDefaults(defineProps<Props>(), {
  collapsed: false,
});
</script>
