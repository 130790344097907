<template>
  <a :href="customerAppUrl">
    <img :src="ogLogo" alt="" />
  </a>
</template>

<script setup lang="ts">
import ogLogo from "~/assets/og-logo.svg?url";
const customerAppUrl = useRuntimeConfig().public.customerAppUrl;
</script>
