<template>
  <div
    class="fixed z-10 h-[50px] w-full shrink-0 bg-steel text-grey-300 transition-all duration-100 md:relative md:h-full md:w-[208px]"
  >
    <div class="flex h-full w-full md:hidden">
      <EmbeddedCustomerAppNavigationSm :items="navigationItems" />
    </div>
    <div
      class="fixed hidden h-full md:flex"
      :class="{ '!h-[calc(100%-40px)]': isOfficehubImpersonation }"
    >
      <EmbeddedCustomerAppNavigationLg :items="navigationItems" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useUserStore } from "~/store/UserStore";
import { useOfficehub } from "~/utils/use-officehub";

const { isOfficehubImpersonation, isOfficehubEnabled } = useOfficehub();
const { user } = storeToRefs(useUserStore());
const { t: $t } = useI18n();

const navigationItems = computed(() => {
  return {
    main: mainNavigationItems.value,
    sub: subNavigationItems.value,
  };
});

const mainNavigationItems = computed(() => {
  const links: CustomerApp.NavigationItem[] = [
    {
      to: "",
      label: $t("customer.home.title"),
      iconName: "customer-app-house",
      order: 10,
    },
    {
      to: "tasks",
      label: $t("customer.tasks.title"),
      iconName: "customer-app-clipboard",
      order: 20,
    },
    {
      to: "orders",
      label: $t("customer.orders.title"),
      iconName: "customer-app-receipt",
      order: 25,
    },
    {
      to: "conversations",
      label: $t("customer.messages"),
      iconName: "customer-app-chat-bubble",
      badge: {
        color: "red",
        // TODO: No unread count
        text: null,
        // text: unreadCount || null,
        show: true,
      },
      order: 30,
    },
    {
      to: "calendar",
      label: $t("customer.calendar.title"),
      iconName: "customer-app-calendar-frame",
      order: 50,
    },
    {
      to: "services",
      label: $t("customer.marketplace"),
      iconName: "customer-app-marketplace-shop",
      order: 60,
      distanced: true,
    },
    {
      to: "partners",
      label: $t("customer.partners_list.title"),
      iconName: "customer-app-persons_group",
      order: 40,
    },
  ];

  if (isOfficehubEnabled.value) {
    links.push({
      to: "officehub/accounts",
      label: "Officehub",
      iconName: "customer-app-officehub",
      order: 70,
    });
  }

  return links.sort((a, b) => a.order - b.order);
});

const subNavigationItems = computed(() => {
  const subLinks: CustomerApp.NavigationItem[] = [];

  if (user.value?.customer?.rights?.owner) {
    subLinks.push({
      to: "settings",
      label: $t("customer.settings.company_settings"),
      iconName: "customer-app-gear",
      order: 1,
    });
  }

  return subLinks;
});
</script>
