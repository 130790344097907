<template>
  <a
    :href="`${customerAppUrl}${avatar.to}`"
    class="flex"
    :class="!collapsed ? 'w-[208px]' : 'w-[50px]'"
  >
    <div>
      <div
        v-if="initials"
        :style="avatarStyles()"
        :class="
          avatarSizeStyles[props.avatarSize as keyof typeof avatarSizeStyles]
        "
        class="flex items-center justify-center"
      >
        <span
          class="h-[13px] max-w-[80%] overflow-hidden break-all text-center text-[13px] font-medium leading-none text-white"
          >{{ initials }}</span
        >
      </div>
    </div>
    <div
      v-if="!collapsed"
      class="ml-3 mt-[-2px] flex w-[104px] flex-col text-grey-300 transition-colors duration-200 ease-in-out hover:text-white"
    >
      <span
        class="block w-full truncate text-body font-semibold"
        :title="avatar.label"
        >{{ avatar.label }}</span
      >
      <span
        class="block w-full truncate text-caption"
        :title="avatar.subLabel"
        >{{ avatar.subLabel }}</span
      >
    </div>
  </a>
</template>
<script setup lang="ts">
import { avatarInitials } from "@officeguru/webapp-shared/src/helpers/avatar-helper";
import { storeToRefs } from "pinia";
import { useUserStore } from "~/store/UserStore";
const customerAppUrl = useRuntimeConfig().public.customerAppUrl;
const { user } = storeToRefs(useUserStore());

interface Props {
  collapsed: boolean;
  avatarSize: string;
}

const props = withDefaults(defineProps<Props>(), {
  collapsed: false,
  avatarSize: "small",
});

const avatarSizeStyles = {
  small: "w-[24px] h-[24px] text-body rounded-lg",
  default: "w-[40px] h-[40px] text-body rounded-lg",
};

const initials = computed(() => {
  return avatarInitials(user.value?.name || "?");
});

const avatar = computed(() => {
  return {
    label: user.value?.name || "loading...",
    subLabel: user.value?.customer?.name || "loading...",
    to: "profile",
  };
});

function avatarStyles() {
  function hue() {
    let charVal = 0;
    const seed = initials.value;
    for (let i = 0; i < seed.length; i += 1) {
      charVal += seed.charCodeAt(i) * 1000;
    }
    return charVal % 360;
  }

  return {
    background: `linear-gradient(135deg,hsla(${hue()}, 42%, 56%, 1) 0%, hsla(${hue()}, 39%, 51%, 1) 100%)`,
    width: props.avatarSize,
    height: props.avatarSize,
  };
}
</script>
