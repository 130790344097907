<template>
  <div>
    <a
      v-if="item.to !== 'services'"
      :href="`${customerAppUrl}${item.to}`"
      class="flex items-center px-[16px] py-[18px] transition-colors duration-200 ease-in-out hover:text-white"
      :class="{
        'mt-6': item.distanced,
        'pointer-events-none text-white': item.to === 'services',
      }"
    >
      <BaseIcon
        :icon="item.iconName"
        :size="18"
        :style="{ 'margin-bottom': item.marginBottom }"
      />
      <span class="ml-3 text-body" :class="!labels ? 'hidden' : 'inline'">{{
        item.label
      }}</span>
    </a>
    <NuxtLink
      v-else
      :to="cmsValues?.link_marketplace_page"
      class="flex items-center px-[16px] py-[18px] text-white transition-colors duration-200 ease-in-out hover:text-white"
      @click="hideMobileCustomerNav"
    >
      <BaseIcon
        :icon="item.iconName"
        :size="18"
        :style="{ 'margin-bottom': item.marginBottom }"
      />
      <span class="ml-3 text-body" :class="!labels ? 'hidden' : 'inline'">{{
        item.label
      }}</span>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import { useUIStore } from "~/store/UIStore";
import { useCMSStore } from "~/store/CMSStore";
const { hideMobileCustomerNav } = useUIStore();
const { cmsValues } = useCMSStore();
const customerAppUrl = useRuntimeConfig().public.customerAppUrl;

interface Props {
  item: CustomerApp.NavigationItem;
  labels?: Boolean;
}

defineProps<Props>();
</script>
